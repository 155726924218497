import React from "react";
import "./homepage.css";
import "../fonts/autography/Autography.otf";
// import ManasiCaveSelfie from "../img/ManasiPatilSelfieCave.png";
import SpaceGlow from "../img/EricaAndersonSpaceGif.gif";
// import NavBar from "../components/navbar";
// import CardDisplay from "../components/cardDisplay";


function HomePage() {
    return (
        <>
            
            <div className="header-container">
                <img className="space-gif" src={SpaceGlow} alt="Space animation" />
                <div className="text-overlay">
        
                    <h1 className="my-name">Coming Soon</h1>
                </div>
            </div>

            <footer>
                <p>Stock gif by Erica Anderson on Giphy. <br/>
                    &copy; Manasi Patil 2025. All rights reserved.</p>
            </footer>
        </>
    );
}

export default HomePage;